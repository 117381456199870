<template>
  <div>
    <svg
      :height="radius * 2"
      :width="radius * 2"
    >
      <circle
        class="text-primary-dark"
        stroke="currentColor"
        fill="transparent"
        :stroke-dasharray="`${circumference} ${circumference}`"
        :style="{ strokeDashoffset }"
        :stroke-width="stroke"
        :r="normalizedRadius"
        :cx="radius"
        :cy="radius"
      />
    </svg>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  radius: {
    type: Number,
    required: true,
  },
  progress: {
    type: Number,
    required: true,
  },
  stroke: {
    type: Number,
    required: true,
  },
})

const normalizedRadius = ref(props.radius - props.stroke * 2)
const circumference = ref(normalizedRadius.value * 2 * Math.PI)

const strokeDashoffset = computed(() => {
  return circumference.value - props.progress / 100 * circumference.value
})
</script>
